<template>
  <section id="cashbox-sales">
    <!-- <h1 class="mb-4">Кассовые продажи</h1> -->

    <div class="b-filter-date">
      <date-picker
        v-model="data_range"
        type="datetimerange"
        format="dd.MM.yyyy"
        class="elm-calendar"
        placeholder="Выберите период"
        :clearable="false"
        @on-change="updateDateRange"
      />
    </div>
    <div class="table-head">
      <documents-table-filter
        ref="doc_table_filter"
        :total="total_doc"
        :selected_sum="selected_sum"
        placeholder="№ Документа"
        table_name="sales-docs"
      />
    </div>
    <b-card
      class="p-0 card-sales"
      no-body
    >
      <div class="table-inner">
        <b-table
          id="table-docs"
          ref="table_cashbox_sales"
          :fields="fields"
          :items="sales"
          :busy="tableIsBusy"
          empty-filtered-text="Нет продаж"
          show-empty
          class="table-docs"
          @row-clicked="trClickItem"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                variant="primary"
                label="Загрузка..."
              />
              <div><strong>Загрузка...</strong></div>
            </div>
          </template>
          <template #empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <template #head(id)>
            <b-form-checkbox v-model="select_all" />
          </template>

          <template #cell(id)="data">
            <div class="th-wrapper d-flex">
              <div
                class="b-status"
                :class="{ success: data.item.fixed }"
              ></div>
              <b-form-checkbox
                v-model="selected"
                class="cursor"
                :value="data.value"
              />
            </div>
          </template>

          <template #cell(number)="{ item }">
            <span class="d-flex float-left">
              {{ item.number }}
            </span>
          </template>

          <template #cell(date)="data">
            <span class="d-flex float-left">
              {{ convertDate(data.value) }}
            </span>
          </template>

          <template #cell(retailSum)="data">
            <span class="d-flex float-right">{{ formatMoney(data.value || 0) }}</span>
          </template>

          <template #cell(entity)>
            {{ currentBranch.name }}
          </template>

          <template #cell(storage)="data">
            {{ data.value.name || 'Нет данных' }}
          </template>

          <template #cell(fixed)="{ item }">
            <b-badge :class="{ success: item.fixed }">
              {{ item.fixed ? 'Проведен' : 'Черновик' }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-card>
    <pagination
      :skip="pagination.skip"
      :total="total"
      table_name="cash-sales"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { formatMoney } from '@/utils/formatMoney'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'

  // import Filters from '../../components/Filters.vue'

  export default {
    components: {
      DocumentsTableFilter,
      Pagination
      // Filters
    },

    apollo: {
      OutgoingHeads: {
        query: require('../../gql/getOutgoingHeads.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id,
            branch: this.currentBranch.id,
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null,
            operation: ['purchase'],
            data_range: {
              begin: this.data_range[0],
              end: this.data_range[1]
            },
            documentPagination: {
              skip: this.pagination.skip,
              take: this.pagination.take
            }
          }
        },
        result({ data }) {
          this.sales = data?.OutgoingHeads?.documents ?? []
          this.total = data?.OutgoingHeads?.total ?? 0
          this.total_doc = {
            pur_sum: data?.OutgoingHeads?.pur_sum ?? 0,
            ret_sum: data?.OutgoingHeads?.ret_sum ?? 0,
            nds_sum: data?.OutgoingHeads?.nds_sum ?? 0
          }
          this.tableIsBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        watchLoading(isLoading) {
          this.tableIsBusy = isLoading
        }
      }
    },

    data: function () {
      return {
        selected_sum: null,
        total_doc: {
          pur_sum: 0,
          ret_sum: 0,
          nds_sum: 0
        },
        tableIsBusy: true,
        data_range: [],
        sales: [],
        total: 0,
        select_all: false,
        selected: [],
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center p-0 id',
            thStyle: 'width: 40px'
          },
          {
            key: 'number',
            label: '№',
            class: 'center',
            thStyle: 'width: 20px'
          },
          {
            key: 'ext_number',
            label: '№ смены',
            class: 'center',
            thStyle: 'width: 20px'
          },
          {
            key: 'date',
            label: 'Дата',
            class: 'center',
            sortable: true,
            thStyle: 'width: 80px'
          },
          {
            key: 'retailSum',
            label: 'Итого, ₽',
            class: 'center',
            sortable: true,
            thStyle: 'width: 80px'
          },
          {
            key: 'entity',
            label: 'Юр лицо отгрузки',
            sortable: true,
            thStyle: 'width: 200px'
          },
          {
            key: 'storage',
            label: 'Место хранения',
            sortable: true,
            thStyle: 'width: 200px'
          },
          {
            key: 'fixed',
            label: 'Статус документа',
            class: 'center',
            sortable: true,
            thStyle: 'width: 100px'
          }
        ]
      }
    },

    watch: {
      selected() {
        if (!this.selected.length) this.selected_sum = null
        else {
          let selected_docs = this.sales.filter((obj) => this.selected.some((item) => item === obj.id))
          this.selected_sum = selected_docs.reduce(
            (acc, doc) => {
              return {
                sum: acc.sum + (+doc.sum ?? 0),
                retailSum: acc.retailSum + (+doc?.retailSum ?? 0),
                nds: acc.nds + (+doc?.nds_sum ?? 0)
              }
            },
            { sum: 0, retailSum: 0, nds: 0 }
          )
        }
      },
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.sales.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },

    mounted() {
      this.prepareDates()
    },

    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      updateDateRange() {
        this.$apollo.queries.OutgoingHeads.refetch()
      },

      trClickItem({ id }) {
        this.$nextTick(() => {
          this.$router.push({
            name: 'operation-process.cashbox.detail',
            params: { id }
          })
        })
      },

      prepareDates() {
        const one_month_ago = new Date()

        this.data_range = [new Date(one_month_ago.setMonth(one_month_ago.getMonth() - 1)), new Date()]
      },

      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      formatMoney(value) {
        return formatMoney(value, 2)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-head {
    background: #fff;
    margin-top: 16px;
    padding: 16px;
    border: 1px solid #e1e1e1;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .form-control {
      width: 220px;
    }
  }
  #cashbox-sales {
    display: flex;
    flex-direction: column;
    flex: 1;
    .b-input-search {
      max-width: 100%;
      width: 268px;
    }
    .b-filter-date {
      width: 208px;
    }
    .actions-panel {
      gap: 10px 16px;
      flex-wrap: wrap;
    }
    .table-actions {
      margin-top: 16px;
      background: #fff;
      border: 1px solid #e1e1e1;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom: none;
      .btn-filters {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  ::v-deep .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }

  :deep .table-docs {
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;
    border-top: none !important;
    border-collapse: separate;
    border-spacing: 0;
    tbody {
      tr:first-child {
        th {
          border-top: none;
        }
      }
    }
    tr:not(:nth-child(1)) {
      td {
        border-top: 1px solid #e1e1e1 !important;
      }
    }

    tr {
      cursor: pointer;
    }
    thead {
      top: -1px;
      background-color: #fff;
      position: sticky;
      z-index: 100;
      th {
        border-bottom: 1px solid #e1e1e1 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bcbcbc;
    }
    tbody {
      tr {
        th.center {
          div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    th.center:not(.id):not(:nth-child(7)) {
      position: relative !important;
      div {
        display: flex !important;
        float: left !important;
      }
    }
    th.center {
      position: relative;
      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translate(0%, -50%);
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;

        &.success {
          background: #00cb91;
        }
      }
    }
  }

  .table-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 0;
  }

  .card-sales {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background: #bebebe;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
  }

  @media (max-height: 700px) {
    :deep .table-docs {
      max-height: 100% !important;
    }
  }
</style>
