var render = function render(){
  var _vm$search, _ref, _vm$selected_sum$sum, _vm$selected_sum, _vm$total, _ref2, _vm$selected_sum$reta, _vm$selected_sum2, _vm$total2, _ref3, _vm$selected_sum$nds, _vm$selected_sum3, _vm$total3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form', {
    staticClass: "pos-r",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerPagination.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), ((_vm$search = _vm.search) === null || _vm$search === void 0 ? void 0 : _vm$search.length) > 0 ? _c('b-button', {
    staticClass: "btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _vm.edit_fields ? _c('b-dropdown', {
    staticClass: "ml-3",
    staticStyle: {
      "height": "32px",
      "width": "32px"
    },
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }], null, false, 1204376047)
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1) : _vm._e()], 1), _vm.total ? _c('div', {
    staticClass: "ml-3 d-flex align-items-center totals float-right"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_vm._v("Сумма: " + _vm._s(_vm.formatPrice((_ref = (_vm$selected_sum$sum = (_vm$selected_sum = _vm.selected_sum) === null || _vm$selected_sum === void 0 ? void 0 : _vm$selected_sum.sum) !== null && _vm$selected_sum$sum !== void 0 ? _vm$selected_sum$sum : (_vm$total = _vm.total) === null || _vm$total === void 0 ? void 0 : _vm$total.pur_sum) !== null && _ref !== void 0 ? _ref : 0)) + " ₽")]), !_vm.hide_retail ? _c('div', {
    staticClass: "mr-3"
  }, [_vm._v(" Сумма розн.: " + _vm._s(_vm.formatPrice((_ref2 = (_vm$selected_sum$reta = (_vm$selected_sum2 = _vm.selected_sum) === null || _vm$selected_sum2 === void 0 ? void 0 : _vm$selected_sum2.retailSum) !== null && _vm$selected_sum$reta !== void 0 ? _vm$selected_sum$reta : (_vm$total2 = _vm.total) === null || _vm$total2 === void 0 ? void 0 : _vm$total2.ret_sum) !== null && _ref2 !== void 0 ? _ref2 : 0)) + " ₽ ")]) : _vm._e(), _c('div', [_vm._v("Сумма НДС: " + _vm._s(_vm.formatPrice((_ref3 = (_vm$selected_sum$nds = (_vm$selected_sum3 = _vm.selected_sum) === null || _vm$selected_sum3 === void 0 ? void 0 : _vm$selected_sum3.nds) !== null && _vm$selected_sum$nds !== void 0 ? _vm$selected_sum$nds : (_vm$total3 = _vm.total) === null || _vm$total3 === void 0 ? void 0 : _vm$total3.nds_sum) !== null && _ref3 !== void 0 ? _ref3 : 0)) + " ₽")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }