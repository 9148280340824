var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "cashbox-sales"
    }
  }, [_c('div', {
    staticClass: "b-filter-date"
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "type": "datetimerange",
      "format": "dd.MM.yyyy",
      "placeholder": "Выберите период",
      "clearable": false
    },
    on: {
      "on-change": _vm.updateDateRange
    },
    model: {
      value: _vm.data_range,
      callback: function ($$v) {
        _vm.data_range = $$v;
      },
      expression: "data_range"
    }
  })], 1), _c('div', {
    staticClass: "table-head"
  }, [_c('documents-table-filter', {
    ref: "doc_table_filter",
    attrs: {
      "total": _vm.total_doc,
      "selected_sum": _vm.selected_sum,
      "placeholder": "№ Документа",
      "table_name": "sales-docs"
    }
  })], 1), _c('b-card', {
    staticClass: "p-0 card-sales",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_c('b-table', {
    ref: "table_cashbox_sales",
    staticClass: "table-docs",
    attrs: {
      "id": "table-docs",
      "fields": _vm.fields,
      "items": _vm.sales,
      "busy": _vm.tableIsBusy,
      "empty-filtered-text": "Нет продаж",
      "show-empty": ""
    },
    on: {
      "row-clicked": _vm.trClickItem
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "th-wrapper d-flex"
        }, [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.fixed
          }
        }), _c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "cell(number)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(item.number) + " ")])];
      }
    }, {
      key: "cell(date)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.convertDate(data.value)) + " ")])];
      }
    }, {
      key: "cell(retailSum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(_vm._s(_vm.formatMoney(data.value || 0)))])];
      }
    }, {
      key: "cell(entity)",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.currentBranch.name) + " ")];
      },
      proxy: true
    }, {
      key: "cell(storage)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name || 'Нет данных') + " ")];
      }
    }, {
      key: "cell(fixed)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('b-badge', {
          class: {
            success: item.fixed
          }
        }, [_vm._v(" " + _vm._s(item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }])
  })], 1)]), _c('pagination', {
    attrs: {
      "skip": _vm.pagination.skip,
      "total": _vm.total,
      "table_name": "cash-sales"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }