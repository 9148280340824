<template>
  <div class="table-filter d-flex justify-content-between">
    <div class="d-flex">
      <b-form
        class="pos-r"
        @submit.prevent="handlerPagination"
      >
        <b-form-input
          v-model="search"
          type="text"
          class="filter-search"
          :placeholder="placeholder"
        />
        <b-button
          v-if="search?.length > 0"
          class="btn-clear-search-str"
          @click.prevent="clear"
        />
      </b-form>
      <!--    <b-button v-if="load_categories" v-b-modal.categories-modal class="ml-3">-->
      <!--      Загрузка групп товаров</b-button-->
      <!--    >-->
      <b-dropdown
        v-if="edit_fields"
        no-caret
        variant="none"
        class="ml-3"
        style="height: 32px; width: 32px"
      >
        <template #button-content>
          <div class="btn-more">
            <img
              src="/img/icons/settings.svg"
              alt=""
            />
          </div>
        </template>
        <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-if="total"
      class="ml-3 d-flex align-items-center totals float-right"
    >
      <div class="mr-3">Сумма: {{ formatPrice(selected_sum?.sum ?? total?.pur_sum ?? 0) }} ₽</div>
      <div
        v-if="!hide_retail"
        class="mr-3"
      >
        Сумма розн.: {{ formatPrice(selected_sum?.retailSum ?? total?.ret_sum ?? 0) }} ₽
      </div>
      <div>Сумма НДС: {{ formatPrice(selected_sum?.nds ?? total?.nds_sum ?? 0) }} ₽</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      placeholder: {
        type: String,
        default: () => '№ Документ или документ поставщика'
      },
      hide_retail: {
        type: Boolean,
        default: false
      },
      load_categories: {
        type: Boolean,
        default: false
      },
      edit_fields: {
        type: Boolean,
        default: false
      },
      table_name: {
        type: String,
        default: null
      },
      selected_sum: {
        type: [Object, null],
        default: null
      },
      total: Object
    },
    data: () => ({
      search: ''
    }),
    beforeMount() {
      if (this.table_name) {
        this.search = this.getPagination?.[this.table_name]?.search
      }
    },

    computed: {
      ...mapGetters({ getPagination: 'settings/getPagination' })
    },

    methods: {
      handlerPagination() {
        this.$emit('handler_pagination', this.search)
      },

      clear() {
        this.search = ''
        this.$emit('clear')
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-filter {
    .btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
  .table-filter {
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 1023px) {
    .totals {
      display: none !important;
    }
  }
</style>
